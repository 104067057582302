












































































































































import { Estado, Municipio, Pessoa, PessoaDocumento } from "@/core/models/geral";
import { PageBase } from "@/core/models/shared";
import { PessoaService } from "@/core/services/Clientes";
import { Component, Prop, Watch, Vue } from "vue-property-decorator";
import jiff from 'jiff';
import { ArquivoService, EnderecoService } from "@/core/services/shared";
import { UsuarioService } from "@/core/services/geral";
import { Usuario } from "@/core/models/geral/Usuario";

@Component
export default class Home extends PageBase {
    loading: boolean = false;
    item: Pessoa = new Pessoa();
    itemOriginal!: Pessoa;
    service = new PessoaService();
    tabActive: any = {};
    mskTelefone = process.env.VUE_APP_MSK_TELEFONE;
    mskCep = process.env.VUE_APP_MSK_CEP;
    enderecoService = new EnderecoService();
    estados: Estado[] = [];
    municipios: Municipio[] = [];
    usuario: Usuario = new Usuario();
    usuarioOriginal!: Usuario;
    usuarioService = new UsuarioService();
    foto: any = null;

    dialogAlterarEndereco: boolean = false;
    documento = new PessoaDocumento();
    documentoFile: any = null;
 
    @Watch("item.endereco.estadoId")
    onEstadoIdChanged(value: number) {
        this.CarregarMunicipio(value);
    }

    @Watch("dialogAlterarEndereco")
    AlterarEnderecoWatch(){
        if(!this.dialogAlterarEndereco){
            this.documento = new PessoaDocumento();
        }
    }

    @Watch("documentoFile")
    async DocumentoWatch(){
        if(this.documentoFile == null){
            this.documento = new PessoaDocumento();
            return;
        }

        const arquivoService = new ArquivoService();
        let dados = await arquivoService.Ler(this.documentoFile);
        this.documento.nome = this.documentoFile.name;
        this.documento.tamanho = this.documentoFile.size / 1024;
        this.documento.tipo = this.documentoFile.type;
        this.documento.dados = dados.replace(/^[^,]*,/, "");
    }

    mounted(){
        
        this.Carregar();

        this.enderecoService.ObterEstados().then(
            res => {
                this.estados = res.data.items;
            },
            err => {
                if (!err.response) {
                    this.$swal("Aviso", "Não foi possível acessar a API", "error");
                } 
                else {
                    this.$swal("Aviso", err.response.data, "error");
                }
            }
        )
        
    }

    async LoadImage(){

        if (!this.foto)
            return;

        const arquivoService = new ArquivoService();
        const dados = await arquivoService.Ler(this.foto);
        this.usuario.foto = dados.replace(/^[^,]*,/, "");
    } 

    RemoveImage(){
        this.usuario.foto = null;
        this.foto = null;
    }

    CarregarMunicipio(estadoId: number){
        this.enderecoService.ObterMunicipios(estadoId).then(
            res => {
                this.municipios = res.data.items;
            },
            err => this.$swal('Aviso', err.message, 'error')      
        );
    }

    CarregarFoto(){
        this.usuarioService.CarregarFoto(this.app.clienteId).then(
            res => {
                this.usuario.foto = res.data;
                console.log(this.usuario.foto);
            },
            err => {
                if (!err.response) {
                    this.$swal("Aviso", "Não foi possível acessar a API", "error");
                } else {
                    this.$swal("Aviso", err.response.data, "error");
                }
            }
        ) 
    }

    Carregar(){
        this.loading = true;
        this.service.ObterPorId(this.app.clienteId, "Endereco.Municipio.Estado, TipoPessoa, Sexo, Qualificacao.Profissao, Documentos").then(
            res => {
                this.item = new Pessoa(res.data);
                this.itemOriginal = jiff.clone(this.item);
                this.CarregarUsuario();
                this.CarregarFoto();
            },
            err => {
                if (!err.response) {
                    this.$swal("Aviso", "Não foi possível acessar a API", "error");
                } else {
                    this.$swal("Aviso", err.response.data, "error");
                }
            }
        ).finally(() => {
            this.loading = false;
        })        
    }

    CarregarUsuario(){
        this.usuarioService.ObterPorId(this.item.usuarioId).then(
            res => {
                this.usuario = new Usuario(res.data);
                this.usuarioOriginal = jiff.clone(this.usuario);
            },
            err => {
                if (!err.response) {
                    this.$swal("Aviso", "Não foi possível acessar a API", "error");
                } else {
                    this.$swal("Aviso", err.response.data, "error");
                }
            }
        )
    }

    ObterEndereco(cep){
        this.enderecoService.ObterPorCep(cep).then(
            res => {
                let endereco = res.data;
                if (endereco) {
                    this.item.endereco.logradouro = endereco.logradouro;
                    this.item.endereco.bairro = endereco.bairro;
                    this.item.endereco.municipio = endereco.municipio;
                    this.item.endereco.estado = endereco.estado;
                }
            },
            err => {
                this.$swal('Aviso', err.message, 'error');
            }
        );
    }

    AlterarEndereco(alterar: boolean){
        if(alterar){
            if(this.documentoFile == null){
                this.$swal("Aviso", "Nenhum arquivo foi inserido!", "warning");
            }
            else{
                this.documento.trocaEndereco = true;
                this.documento.pessoaId = this.item.id;
                this.item.documentos.push(this.documento);
                this.Salvar().then(
                    res => {
                        this.$swal("Aviso", 'Sua solicitação de alteração de endereço foi enviada com sucesso!', "success");
                        this.Carregar();
                    } 
                )
            }
        }
        else{
            this.dialogAlterarEndereco = true
        }
    }

    async Salvar(){
        let patchModel = jiff.diff(this.itemOriginal, this.item, false);

        await this.service.Salvar(patchModel, this.item.id).then(
            res => {
                this.SalvarUsuario();
            },
            err => {
                if (err.response.status == 400) {
                    this.$swal("Aviso", err.response.data.title, "warning");
                } else {
                    this.$swal("Aviso", err.response.data, "error");
                }
            }
        )
    }

    async SalvarUsuario(){
        let patchModel = jiff.diff(this.usuarioOriginal, this.usuario, false);

        await this.usuarioService.Salvar(patchModel, this.usuario.id).then(
            res => {
                if(!this.dialogAlterarEndereco){
                    this.$swal("Aviso", "Operação realizada com sucesso!", res.status == 201 || res.status == 200 ? "success" : "warning");
                    this.Carregar();
                }
            },
            err => {
                if (err.response.status == 400) {
                    this.$swal("Aviso", err.response.data.title, "warning");
                } else {
                    this.$swal("Aviso", err.response.data, "error");
                }
            }
        ).finally(() => {
            this.dialogAlterarEndereco = false;
        })
    }

}
